// import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined'
import { Box, Card, Container, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import about_left from 'assets/Home/about_left.jpg'
import Animate from 'utils/ScrollAnimation'

const AboutUs = () => {
  return (
    <Container>
      <Grid container spacing={5} alignItems="stretch">
        <Grid item xs={12} sm={6}>
          <Animate anim="fade-up" anchor="center-bottom">
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography
                align="left"
                sx={{
                  typography: {
                    xs: 'subtitle1',
                    sm: 'h6',
                  },
                  fontWeight: 'bold !important',
                }}
                color="primary.main"
              >
                ABOUT US
              </Typography>
              <Typography
                sx={{
                  typography: {
                    xs: 'h4',
                    sm: 'h3',
                  },
                  fontWeight: 'bold !important',
                }}
                gutterBottom
              >
                Renewable Energy for a Sustainable World
              </Typography>
              <Typography align="justify" variant="subtitle2" gutterBottom>
                Prospect Gaze is an independent consulting firms in the
                renewable energy sector established in 2012. Prospect Gaze
                deliver technical, financial and legal support throughout the
                entire life cycle of renewable energy facilities. Prospect Gaze
                also provides services for public organisations and private
                companies cover in Agriculture & Agribusiness the whole range
                from primary production to processing and marketing. Financial
                Sector Development focuses on developing tailor-made credit and
                saving products and establishing an appropriate regulation and
                supervision system.
              </Typography>
              <Card
                elevation={0}
                sx={{
                  mt: 2,
                  boxShadow: 'none',
                  bgcolor: 'var(--tertiary)',
                  p: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                }}
              >
                <WbIncandescentOutlinedIcon
                  sx={{
                    transform: 'rotate(180deg)',
                    color: 'var(--primary)',
                    mr: 1,
                    fontSize: 40,
                  }}
                />
                <Box>
                  <Typography
                    align="left"
                    fontWeight="bold"
                    variant="subtitle1"
                  >
                    Your idea, our solution
                  </Typography>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    Our expertise, your prosperity - unlocking growth
                    opportunities with strategic investments.
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Animate>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Animate height="100%" anim="fade-up" anchor="center-bottom">
            <img
              src={about_left}
              alt="about_left"
              width="100%"
              height="100%"
              style={{
                height: '100%',
                borderRadius: 8,
                display: 'block',
              }}
            />
          </Animate>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AboutUs
