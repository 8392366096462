import { Container, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import businessCard1 from 'assets/Home/business_card-1.jpg'
import businessCard2 from 'assets/Home/business_card-2.jpg'
import businessCard3 from 'assets/Home/business_card-3.jpg'
import businessCard4 from 'assets/Home/solar_panel2.jpg'
import BusinessCard from './BusinessCard'

const OurBusiness = () => {
  return (
    <Box
      sx={{
        my: 10,
      }}
    >
      <Box
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
        }}
      >
        <Container
          sx={{
            py: 15,
            pb: 20,
          }}
          maxWidth="md"
        >
          <Typography
            align="center"
            color="primary.main"
            variant="subtitle1"
            fontWeight="bold"
            gutterBottom
          >
            OUR BUSINESS
          </Typography>
          <Typography
            align="center"
            sx={{
              typography: {
                xs: 'h4',
                sm: 'h3',
              },
              fontWeight: 'bold !important',
            }}
            gutterBottom
          >
            Sunrise E&T Background
          </Typography>
          <Typography align="center" variant="subtitle2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <Grid
          sx={{
            transform: 'translateY(-100px)',
          }}
          container
          spacing={3}
          alignItems="stretch"
        >
          <Grid item xs={12} sm={3}>
            <BusinessCard image={businessCard1} title="Sunrise Fish Farms" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <BusinessCard
              image={businessCard2}
              title="Sunrise Drinking Water Pipe"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <BusinessCard
              image={businessCard3}
              title="Cable Bridge Protection"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <BusinessCard image={businessCard4} title="Floating Solar PV" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default OurBusiness
