import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Aos from 'aos'
import Footer from 'components/Footer'
import Navbar from 'components/Navbar/MainNav'
import { useEffect } from 'react'
import AppRoutes from 'Routes'
import ScrollToTop from 'utils/ScrollToTop'

const App = () => {
  // const { darkmode, setDarkmode } = useStateContext()
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#0FCC7C',
      },
      secondary: {
        main: '#00384f',
      },

      text: {
        primary: '#00384F',
        // secondary: '#FFFFFF',
      },
      // background: {
      //   default: '#1d1b19',
      //   paper: '#181715',
      // },
    },
    typography: {
      // fontFamily: 'Source Sans Pro',
      // fontWeightLight: 400,
      // fontWeightRegular: 500,
      // fontWeightMedium: 600,
      // fontWeightBold: 700,
      button: {
        textTransform: 'none',
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 8,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'var(--card-shadow)',
            borderRadius: 12,
          },
        },
      },
    },
  })

  useEffect(() => {
    Aos.init({ duration: 500, once: true })
  })
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />

      <Navbar />
      <AppRoutes />
      <Footer />
    </ThemeProvider>
  )
}

export default App
