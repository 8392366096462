// import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined'
import { Box, Card, Container, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import about_left from 'assets/Home/about_left.jpg'

const AboutCompany = () => {
  return (
    <Container
      sx={{
        my: 10,
      }}
    >
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography
              align="left"
              fontWeight="bold"
              variant="subtitle2"
              color="primary.main"
            >
              ABOUT COMPANY
            </Typography>
            <Typography
              align="left"
              variant="h3"
              fontWeight="bold"
              gutterBottom
            >
              Renewable Energy for a Sustainable World
            </Typography>
            <Typography align="justify" variant="subtitle2" gutterBottom>
              Prospect Gaze is an independent consulting firms in the renewable
              energy sector established in 2012. Prospect Gaze deliver
              technical, financial and legal support throughout the entire life
              cycle of renewable energy facilities. Prospect Gaze also provides
              services for public organisations and private companies cover in
              Agriculture & Agribusiness the whole range from primary production
              to processing and marketing. Financial Sector Development focuses
              on developing tailor-made credit and saving products and
              establishing an appropriate regulation and supervision system.
            </Typography>
            <Card
              elevation={0}
              sx={{
                mt: 2,
                boxShadow: 'none',
                bgcolor: 'var(--tertiary)',
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <WbIncandescentOutlinedIcon
                sx={{
                  transform: 'rotate(180deg)',
                  color: 'var(--primary)',
                  mr: 1,
                  fontSize: 40,
                }}
              />
              <Box>
                <Typography align="left" fontWeight="bold" variant="subtitle1">
                  Your idea, our solution
                </Typography>
                <Typography
                  align="left"
                  variant="subtitle2"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Our expertise, your prosperity - unlocking growth
                  opportunities with strategic investments.
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src={about_left}
            alt="about_left"
            width="100%"
            height="100%"
            style={{
              borderRadius: 8,
              display: 'block',
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default AboutCompany
