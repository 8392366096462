import AirIcon from '@mui/icons-material/Air'
import LightModeIcon from '@mui/icons-material/LightMode'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import { Container, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

const TintCard = () => {
  return (
    <Box
      sx={{
        py: 10,
        bgcolor: 'var(--tertiary)',
        mb: 8,
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" 
        sx={{
          typography: {
            xs: 'h4',
            sm: 'h3',
          },
          fontWeight: '800 !important',
        }}
      gutterBottom>
          O&M supervision
        </Typography>
        <br />
        <Typography
          sx={{
            textAlign: 'justify',
            fontWeight: 400,
          }}
          variant="subtitle1"
          gutterBottom
        >
          Prospect Gaze take care of monitoring the performance of the asset
          during operation and maintenance. To this end, we maintain direct and
          ongoing communication with the team of professionals working on the
          project. Here we can work holistically or on specific tasks – asset
          and evacuation infrastructure, project failures, defects and
          breakdowns, energy harvesting systems, trip reports, electrical system
          failures and defects, site maintenance and project performance.
        </Typography>

        <br />
        <Typography
          sx={{
            textAlign: 'justify',
            fontWeight: 400,
          }}
          variant="subtitle1"
          gutterBottom
        >
          Prospect Gaze also provide support in the annual validation of the
          preventive O&M plan together with the fulfillment of the obligations
          contained in the contract and the handling of any claims. We also
          coordinate the required corrective maintenance repairs at the plant
          with the O&M company.
        </Typography>

        <Grid
          sx={{
            mt: 2,
          }}
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& span': {
                  color: 'var(--primary)',
                },
              }}
            >
              <PeopleAltOutlinedIcon
                sx={{ fontSize: 70, color: 'primary.main', mr: 2 }}
              />
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  20<span>+</span>
                </Typography>
                <Typography variant="subtitle2">Happy Client</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& span': {
                  color: 'var(--primary)',
                },
              }}
            >
              <AirIcon sx={{ fontSize: 70, color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  20<span>+</span>
                </Typography>
                <Typography variant="subtitle2">Wind Farm</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& span': {
                  color: 'var(--primary)',
                },
              }}
            >
              <LightModeIcon
                sx={{ fontSize: 70, color: 'primary.main', mr: 2 }}
              />
              <Box>
                <Typography variant="h4" fontWeight="bold">
                  20<span>+</span>
                </Typography>
                <Typography variant="subtitle2">Solar Farm</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default TintCard
