import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Box, Button, Container, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

const Social = () => {
  return (
    <Container
      sx={{
        mb: 10,
      }}
    >
      <Typography
        sx={{
          typography: {
            xs: 'h5',
            sm: 'h4',
          },
          textAlign: 'center',
          fontWeight: 'bold !important',
        }}
        gutterBottom
      >
        Follow our official social network
      </Typography>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            sx={{
              p: 2,
            }}
            color="secondary"
            variant="contained"
          >
            <FacebookIcon />
          </Button>
          <Button
            sx={{
              p: 2,
            }}
            color="secondary"
            variant="contained"
          >
            <InstagramIcon />
          </Button>

          <Button
            sx={{
              p: 2,
            }}
            color="secondary"
            variant="contained"
          >
            <LinkedInIcon />
          </Button>
          <Button
            sx={{
              p: 2,
            }}
            color="secondary"
            variant="contained"
          >
            <TwitterIcon />
          </Button>
        </Stack>
      </Box>
    </Container>
  )
}

export default Social
