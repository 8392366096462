import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import Marquee from 'react-fast-marquee'

import logo1 from 'assets/Home/partners/logo-1.png'
import logo2 from 'assets/Home/partners/logo-2.png'
import logo3 from 'assets/Home/partners/logo-3.png'
import logo4 from 'assets/Home/partners/logo-4.png'
import logo5 from 'assets/Home/partners/logo-5.png'
import logo6 from 'assets/Home/partners/logo-6.png'

const Partners = () => {
  const data = [logo1, logo2, logo3, logo4, logo5, logo6]
  return (
    <Container
      maxWidth="xl"
      sx={{
        mt: {
          xs: -4,
          sm: 0,
        },
        mb: { xs: 3, sm: 5 },
      }}
    >
      <Marquee>
        {data.map((img, i) => (
          <Box sx={{ my: 2, mx: 5, p: 1 }} key={i}>
            <img height={100} src={img} alt={i} />
          </Box>
        ))}
      </Marquee>
    </Container>
  )
}

export default Partners
