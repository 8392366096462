import { Container, Hidden } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import windTurbine from 'assets/Home/wind_turbine.jpg'
import BottomNav from 'components/Navbar/BottomNav'
import MyNavLink from 'utils/MyNavLink'

const Single = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Box
        className="zoom"
        sx={{
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), 
           url(${windTurbine})`,
          backgroundSize: 'cover',

          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <BottomNav />
      </Box>
      <Container
        maxWidth="sm"
        sx={{
          pt: 15,
          pb: 25,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            typography: {
              xs: 'subtitle1',
              sm: 'subtitle1',
            },
            fontWeight: 'bold !important',
          }}
          // fontWeight="bold"
          gutterBottom
        >
          Welcome To Prospect Gaze LTD
        </Typography>
        <Typography
          sx={{
            color: 'white',
            typography: { xs: 'h4', sm: 'h2' },
            fontWeight: 'bold !important',
          }}
          gutterBottom
          align="center"
        >
          Cleaner Energy for a Cleaner World
        </Typography>
        <Typography
          sx={{ color: 'white', typography: { xs: 'subtitle2', sm: 'h6' } }}
          // fontWeight="bold"
          gutterBottom
          align="center"
        >
          Consultancy & Project Finance Solutions for Renewable Energy &
          Horticulture Projects
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Button
            fullWidth
            sx={{
              mr: 1,
              color: '#fff',
              // borderRadius: 8,
              border: '2px solid var(--primary)',
              typography: { xs: 'subtitle1', sm: 'h6' },
              '&:hover': {
                border: '2px solid #FFFFFF',
              },
            }}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
          >
            <Hidden smDown>
              <MyNavLink to="contact" name="Contact Us" offset={-200} />
            </Hidden>
            <Hidden smUp>
              <MyNavLink to="contact" name="Contact Us" />
            </Hidden>
          </Button>

          <Button
            fullWidth
            sx={{
              color: '#fff',
              border: '2px solid #fff',
              // borderRadius: 8,
              typography: { xs: 'subtitle1', sm: 'h6' },
              '&:hover': {
                color: 'var(--primary)',
                border: '2px solid var(--primary)',
              },
            }}
            variant="text"
            color="primary"
            size="large"
          >
            <MyNavLink to="choose" name="Why choose us" />
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default Single
