import { Box } from '@mui/system'
import AboutUs from 'components/Home/AboutUs'
import Contact from 'components/Home/Contact'
import Hero from 'components/Home/Hero'
import HeroCardPanel from 'components/Home/HeroCardPanel'
import Project from 'components/Home/Project'
import TintCard from 'components/Home/TintCard'
import WhyChooseUs from 'components/Home/WhyChooseUs'

const Home = () => {
  return (
    <Box>
      <Hero />

      <HeroCardPanel />
      <section id="about">
        <AboutUs />
      </section>
      <section id="choose">
        <WhyChooseUs />
      </section>

      <TintCard />
      <section id="project">
        <Project />
      </section>

      {/* <WhyChooseUs /> */}
      {/* <OurProject /> */}
      <section id="contact">
        <Contact />
      </section>
      {/* <Hidden smDown>
        <LiveGreen />
        <Partners />
      </Hidden> */}
    </Box>
  )
}

export default Home
