import DoneIcon from '@mui/icons-material/Done'
import { Container, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Animate from 'utils/ScrollAnimation'
import ContactCard from './ContactCard'

const Contact = () => {
  return (
    <Box
      sx={{
        mt: { xs: 10, sm: 30 },
      }}
    >
      <Box
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          pb: 3,
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={5}
            alignItems="center"
            sx={{
              flexWrap: 'wrap-reverse',
            }}
          >
            <Grid
              item
              xs={12}
              sm={7}
              sx={{
                pb: {
                  xs: 4,
                  sm: '0 !important',
                },
              }}
            >
              <Animate height="100%" anim="fade-up">
                <ContactCard />
              </Animate>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                pt: { xs: 2, sm: '0 !important' },
              }}
            >
              <Box>
                <Typography
                  align="left"
                  sx={{
                    typography: {
                      xs: 'subtitle1',
                      sm: 'h6',
                    },
                    fontWeight: 'bold !important',
                  }}
                  color="primary.main"
                  gutterBottom
                >
                  Contact Us
                </Typography>

                <Typography
                  sx={{
                    typography: {
                      xs: 'h4',
                      sm: 'h3',
                    },
                    fontWeight: 'bold !important',
                  }}
                  gutterBottom
                >
                  Let’s experience the power of the renewable energy.
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  align="left"
                  variant="subtitle2"
                  gutterBottom
                >
                  <DoneIcon
                    color="primary"
                    sx={{
                      fontSize: 30,
                      mr: 1,
                    }}
                  />
                  We are an independent consulting firm specializing in
                  renewable energy.
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  align="left"
                  variant="subtitle2"
                  gutterBottom
                >
                  <DoneIcon
                    color="primary"
                    sx={{
                      fontSize: 30,
                      mr: 1,
                    }}
                  />
                  Specializes in establishing appropriate regulation and
                  supervision systems.
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  align="left"
                  variant="subtitle2"
                  gutterBottom
                >
                  <DoneIcon
                    color="primary"
                    sx={{
                      fontSize: 30,
                      mr: 1,
                    }}
                  />
                  Provides technical, financial, and legal support for renewable
                  energy facilities.
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  align="left"
                  variant="subtitle2"
                  gutterBottom
                >
                  <DoneIcon
                    color="primary"
                    sx={{
                      fontSize: 30,
                      mr: 1,
                    }}
                  />
                  Offer services in Agriculture & Agribusiness, covering primary
                  production to marketing.
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  align="left"
                  variant="subtitle2"
                  gutterBottom
                >
                  <DoneIcon
                    color="primary"
                    sx={{
                      fontSize: 30,
                      mr: 1,
                    }}
                  />
                  Financial Sector Development focuses on creating customized
                  credit and saving products.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default Contact
