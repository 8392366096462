import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Box, Stack } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

const TopNav = () => {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        bgcolor: 'secondary.main',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          variant="dense"
        >
          <Typography
            component="a"
            href="mailto:info@rgbjute.com"
            noWrap
            sx={{
              mr: 2,
              display: 'flex',
              color: 'white',
              textDecoration: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
            }}
          >
            <EmailIcon sx={{ fontSize: 18, mr: 0.5, color: 'primary.main' }} />
            info@prospectgaze.com
          </Typography>
          <Stack direction="row" spacing={2}>
            <Box>
              <FacebookIcon sx={{ fontSize: 15, color: 'white' }} />
            </Box>
            <Box component="a" href="https://wa.me/+447958326988">
              <WhatsAppIcon sx={{ fontSize: 15, color: 'white' }} />
            </Box>
            <Box>
              <LinkedInIcon sx={{ fontSize: 15, color: 'white' }} />
            </Box>
            <Box>
              <TwitterIcon sx={{ fontSize: 15, color: 'white' }} />
            </Box>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default TopNav
