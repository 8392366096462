import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import NewLearnMore from 'utils/NewLearnMore'

const WhyChooseUsCard = ({ title, image, description }) => {
  return (
    <Card
      elevation={0}
      sx={{
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        src={image}
        alt="businessCard"
        width="100%"
        style={{
          objectFit: 'cover',
          height: 200,
        }}
      />
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Typography
          align="center"
          variant="subtitle1"
          fontWeight="bold"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography align="center" variant="caption">
          {description}
        </Typography>
        <Box
          sx={{
            height: '100%',
          }}
        />
        <br />
        <NewLearnMore width="30%" />
      </Box>
    </Card>
  )
}

export default WhyChooseUsCard
