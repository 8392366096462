import { Box } from '@mui/material'
import ContactFormGrid from 'components/Contact/ContactFormGrid'
import ContactHeader from 'components/Contact/ContactHeader'
import MapGrid from 'components/Contact/MapGrid'
import Social from 'components/Contact/Social'

const Contact = () => {
  return (
    <Box>
      <ContactHeader />
      <ContactFormGrid />
      <MapGrid />
      <Social />
    </Box>
  )
}

export default Contact
