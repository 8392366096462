import { Container, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import businessCard1 from 'assets/Home/business_card-3.jpg'
import businessCard2 from 'assets/Home/diligence.jpg'
// import businessCard2 from 'assets/Home/investment.jpg'
// import businessCard3 from 'assets/Home/risk.jpg'
import businessCard3 from 'assets/Home/opportunity2.jpg'
import Animate from 'utils/ScrollAnimation'
import WhyChooseUsCard from './WhyChooseUsCard'

const WhyChooseUs = () => {
  return (
    <Box
      sx={{
        my: 10,
        mb: {
          xs: 3,
          sm: 10,
        },
      }}
    >
      <Box
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
        }}
      >
        <Container
          sx={{
            py: 10,
            pb: 20,
          }}
          maxWidth="md"
        >
          <Typography
            align="center"
            sx={{
              typography: {
                xs: 'subtitle1',
                sm: 'h6',
              },
              fontWeight: 'bold !important',
            }}
            color="primary.main"
            gutterBottom
          >
            WHY CHOOSE US
          </Typography>

          <Typography
            align="center"
            sx={{
              typography: {
                xs: 'h4',
                sm: 'h3',
              },
              fontWeight: 'bold !important',
            }}
            gutterBottom
          >
            10+ Years of Industry Experience.
          </Typography>
          <Typography align="center" variant="subtitle2">
            Unlocking the full potential of renewable energy projects through
            expert appraisal.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Grid
          sx={{
            transform: 'translateY(-100px)',
          }}
          container
          spacing={3}
          alignItems="stretch"
        >
          <Grid item xs={12} sm={4}>
            <Animate height="100%">
              <WhyChooseUsCard
                image={businessCard1}
                title="Appraise renewable energy projects"
                description="We have extensive experiences in appraisal of renewable energy projects or of complete companies prior to any transactions that requires the execution of due diligence."
              />
            </Animate>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Animate height="100%" delay={200}>
              <WhyChooseUsCard
                image={businessCard2}
                title="Conduct due diligence for investment"
                description="During due diligence process the required information is carefully compiled and then an assessment of the opportunities, evaluating feasibilities and then risks is made."
              />
            </Animate>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Animate height="100%" delay={400}>
              <WhyChooseUsCard
                image={businessCard3}
                title="Mitigate risks, identify opportunities"
                description="By conducting a thorough analysis of the information gathered, potential risks can be identified and evaluated, allowing for the development of strategies to mitigate risk."
              />
            </Animate>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WhyChooseUs
