import { Button, Hidden } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import logo from 'assets/logo.png'
// import useNav from 'hooks/useNav'
import MyNavLink from 'utils/MyNavLink'

const pages = [
  { name: 'About', to: 'about' },
  { name: 'Contact', to: 'contact' },
]

const BottomNav = () => {
  // const { value, setValue } = useNav(pages)

  return (
    <AppBar position="static" elevation={0} sx={{ bgcolor: 'transparent' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              py: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <Hidden smDown>
              <img src={logo} alt="logo" height={80} />
            </Hidden>
            <Hidden smUp>
              <img src={logo} alt="logo" height={70} />
            </Hidden>
          </Box>

          <Box
            sx={{
              flexGrow: { xs: 0, sm: 1 },
              justifyContent: 'flex-end',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {pages.map((page) => (
              <Button
                variant="outlined"
                key={page.name}
                sx={{
                  mr: 2,
                  typography: 'subtitle1',
                }}
              >
                {
                  page.name === 'Contact' ? (
                    <>
                      {' '}
                      <Hidden smDown>
                        <MyNavLink
                          to="contact"
                          name="Contact Us"
                          offset={-200}
                        />
                      </Hidden>
                      <Hidden smUp>
                        <MyNavLink to="contact" name="Contact Us" />
                      </Hidden>
                    </>
                  ) : (
                    <MyNavLink to={page.to} name={page.name} />
                  )
                  // <MyNavLink
                  //   to={page.to}
                  //   name={page.name}
                  //   offset={page.name === 'Contact' ? -200 : 0}
                  // />
                }
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default BottomNav
