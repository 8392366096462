import { Container, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Animate from 'utils/ScrollAnimation'
import ProjectCard from './ProjectCard'

const Project = () => {
  return (
    <Box
      sx={{
        mt: {
          xs: -12,
          sm: 0,
        },
        pt: {
          xs: 3,
        },
      }}
    >
      <Container>
        <Typography
          align="center"
          sx={{
            typography: {
              xs: 'subtitle1',
              sm: 'h6',
            },
            fontWeight: 'bold !important',
          }}
          color="primary.main"
          gutterBottom
        >
          Project management
        </Typography>

        <Typography
          align="center"
          sx={{
            typography: {
              xs: 'h4',
              sm: 'h3',
            },
            fontWeight: 'bold !important',
          }}
          gutterBottom
        >
          Let’s add green energy to life
        </Typography>
        <Typography align="justify" variant="subtitle2">
          Prospect Gaze plan, coordinate and supervise the asset’s major
          milestones to ensure the plant’s success. Specifically in terms of
          O&M, we take care of monitoring compliance with obligations, reviewing
          procedures and validating the annual preventive plan, handling any
          relevant claims if necessary. Prospect Gaze also coordinate with the
          O&M company to carry out corrective plant maintenance and manage the
          sale of electricity on the wholesale market with the submission of
          production estimates. What's more, as specialists in technical asset
          management, we also handle the following:
        </Typography>
      </Container>

      <Container
        sx={{
          my: 5,
        }}
      >
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={6}>
            <Animate height="100%">
              <ProjectCard
                title="Managing Local Relationships"
                desc="Direct relationship with the plant’s local environment (municipalities, neighbouring landowners, energy regulators etc.) associated with the operations of the company behind the project."
              />
            </Animate>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Animate height="100%">
              <ProjectCard
                title="Meeting Legal Obligations"
                desc="Management of compliance with the obligations taken on by the company in terms of current permits and licenses for their operations, as well as any necessary regulatory requirements."
              />
            </Animate>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Animate height="100%">
              <ProjectCard
                title="Maintaining Safe Operations"
                desc="Supervision of the work performed by the surveillance company. We also take care of the coordination and follow-up of the steps required in response to a breach of security in the project."
              />
            </Animate>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Animate height="100%">
              <ProjectCard
                title="Verifying the Compliances"
                desc="Verification of compliance with EPC guarantees (ensuring compliance with design contract, supplies required, construction of the facility). If needed, we also handle any relevant claims."
              />
            </Animate>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Project
