import { Link } from 'react-scroll'

function MyNavLink({ name, to, offset = 0 }) {
  return (
    <Link
      activeClass="nav-active"
      to={to}
      spy={true}
      smooth={true}
      hashSpy={true}
      duration={500}
      isDynamic={true}
      ignoreCancelEvents={false}
      spyThrottle={500}
      offset={offset}
      className="nav"
    >
      {name}
    </Link>
  )
}

export default MyNavLink
