import { Box, Container, Grid, Hidden } from '@mui/material'

import Animate from 'utils/ScrollAnimation'
import HeroCardOne from './HeroCardOne'
import HeroCardTwo from './HeroCardTwo'

const HeroCardPanel = () => {
  return (
    <Container>
      <Box
        sx={{
          position: 'relative',
          zIndex: '20',
          transform: {
            xs: 'translateY(-35%)',
            sm: 'translateY(-30%)',
            md: 'translateY(-50%)',
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Hidden smDown>
          <Animate anim="fade-up" anchor="top-center">
            <Grid container spacing={3} alignItems="stretch">
              <Grid item xs={12} sm={4} id="hero-card">
                <HeroCardOne />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} sm={8}>
                  <HeroCardTwo />
                </Grid>
              </Hidden>
            </Grid>
          </Animate>
        </Hidden>
        <Hidden smUp>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={4} id="hero-card">
              <HeroCardOne />
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} sm={8}>
                <HeroCardTwo />
              </Grid>
            </Hidden>
          </Grid>
        </Hidden>
      </Box>
    </Container>
  )
}

export default HeroCardPanel
