import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import { Container, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

const MapGrid = () => {
  return (
    <Box
      sx={{
        bgcolor: 'var(--tertiary)',
        mb: 8,
      }}
    >
      <Grid container alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            '& iframe': {
              height: { xs: 300, sm: '100%' },
            },
          }}
        >
          <iframe
            title="Prospect Gaze LTD"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=39%20Warren%20Road,%20Wanstead,%20London,%20E11%202LX+(ProspectGaze)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Container
            sx={{
              py: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                align="left"
                color="primary.main"
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
              >
                OUR BUSINESS
              </Typography>
              <Typography
                align="left"
                sx={{
                  typography: {
                    xs: 'h4',
                    sm: 'h3',
                  },
                  fontWeight: 'bold !important',
                }}
                gutterBottom
              >
                We're here to help.
              </Typography>
              <Typography
                align="left"
                variant="subtitle2"
                sx={{
                  fontWeight: 400,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Typography>
              <Box
                sx={{
                  mt: 4,
                }}
              >
                {/* <Typography
                  align="left"
                  variant="subtitle2"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  gutterBottom
                >
                  <LocationOnIcon
                    sx={{
                      color: 'primary.main',
                      mr: 2,
                    }}
                  />{' '}
                  39 Warren Road, Wanstead, London, E11 2LX
                </Typography> */}

                <Typography
                  align="left"
                  variant="subtitle2"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    my: 1,
                  }}
                  gutterBottom
                >
                  <EmailIcon
                    sx={{
                      color: 'primary.main',
                      mr: 2,
                    }}
                  />{' '}
                  support@prospectgaze.com
                </Typography>
                <Typography
                  align="left"
                  variant="subtitle2"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <CallIcon
                    sx={{
                      color: 'primary.main',
                      mr: 2,
                    }}
                  />{' '}
                  +(62)21 2002-2012
                </Typography>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MapGrid
