import { Box } from '@mui/material'
import 'aos/dist/aos.css'

const Animate = ({
  children,
  anim = 'zoom-in-up',
  delay = '0',
  height = 'auto',
  anchor = '',
}) => {
  return (
    <Box sx={{ height: { height } }}>
      {anchor === '' ? (
        <Box sx={{ height: { height } }} data-aos={anim} data-aos-delay={delay}>
          {children}
        </Box>
      ) : (
        <Box
          sx={{ height: { height } }}
          data-aos={anim}
          data-aos-delay={delay}
          // data-aos-anchor-placement={anchor}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

export default Animate
