import { Button } from '@mui/material'
import Box from '@mui/material/Box'

const NewLearnMore = ({ width }) => {
  return (
    <Button
      sx={{
        width: width,
      }}
      variant="outlined"
    >
      <Box
        sx={{
          height: 2,
          borderRadius: 4,
          width: '100%',
          bgcolor: 'primary.main',
        }}
      />
    </Button>
  )
}

export default NewLearnMore
