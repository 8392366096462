// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

// import Swiper core and required modules
import Box from '@mui/material/Box'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import Single from './Single'
import SingleTwo from './SingleTwo'

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade])

const Hero = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Swiper
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        speed={1200}
        loop
        grabCursor={true}
        effect={'fade'}
        className="mySwiper"
      >
        <SwiperSlide>
          <Single />
        </SwiperSlide>
        <SwiperSlide>
          <SingleTwo />
        </SwiperSlide>
      </Swiper>
    </Box>
  )
}

export default Hero
