import AutoModeIcon from '@mui/icons-material/AutoMode'
import { Avatar, Card, Divider, Hidden } from '@mui/material'
import Typography from '@mui/material/Typography'
const HeroCardOne = () => {
  return (
    <Card
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        p: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Hidden smDown>
        <Typography align="center" variant="h6" fontWeight="bold">
          Serving with pride since 2012
        </Typography>
        <Divider
          sx={{
            my: 1,
            height: 2,
            width: '50%',
            bgcolor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ bgcolor: 'var(--primary)', height: 50, width: 50 }}>
            <AutoModeIcon
              sx={{
                fontSize: 35,
              }}
            />
          </Avatar>
        </Divider>
      </Hidden>
      <Hidden smUp>
        <Typography align="center" variant="h5" fontWeight="bold">
          Serving with pride since 2012
        </Typography>
      </Hidden>
      <Hidden smUp>
        <Divider
          sx={{
            my: 1,
            height: 2,
            width: '40%',
            bgcolor: 'white',
          }}
        />
      </Hidden>

      <Hidden smDown>
        <Typography align="center" variant="subtitle1" fontWeight="bold">
          Financing renewable energy projects with expertise and passion
        </Typography>
      </Hidden>
      <Hidden smUp>
        <Typography
          align="center"
          sx={{
            fontWeight: 400,
          }}
          variant="h6"
        >
          Financing renewable energy projects with expertise and passion
        </Typography>
      </Hidden>
    </Card>
  )
}

export default HeroCardOne
