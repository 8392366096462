import { Box } from '@mui/material'
import TopNav from './TopNav'

const Navbar = () => {
  return (
    <Box>
      <TopNav />
    </Box>
  )
}
export default Navbar
