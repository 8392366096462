import FlareIcon from '@mui/icons-material/Flare'
import { Avatar, Box, Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import NewLearnMore from 'utils/NewLearnMore'

const ProjectCard = ({ title, desc }) => {
  return (
    <Card
      sx={{
        p: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: { xs: 'center', sm: 'flex-start' },
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Avatar
        sx={{ bgcolor: 'var(--tertiary)', height: 70, width: 70, mb: 2 }}
        variant="rounded"
      >
        <FlareIcon
          sx={{
            color: 'primary.main',
            fontSize: 40,
          }}
        />
      </Avatar>
      <Typography
        align="left"
        variant="subtitle1"
        fontWeight="bold"
        gutterBottom
      >
        {title}
      </Typography>

      <Typography
        sx={{
          textAlign: { xs: 'center', sm: 'justify' },
          // color: 'text.secondary',
        }}
        variant="subtitle2"
        gutterBottom
      >
        {desc}
      </Typography>

      <br />
      <Box
        sx={{
          height: '100%',
        }}
      />
      <NewLearnMore width="25%" />
    </Card>
  )
}

export default ProjectCard
