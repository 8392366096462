import { Toolbar } from "@mui/material"
import Typography from "@mui/material/Typography"

const BottomBar = () => {
  return (
    <Toolbar
      sx={{
        bgcolor: "secondary.main",
        color: "white",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      disableGutters
      variant="dense"
    >
      <Typography
        align="center"
        sx={{
          typography: { xs: "caption", sm: "subtitle2" },
          "& a": {
            color: "var(--primary) !important",
            fontWeight: "bold",
          },
        }}
      >
        Copyright © {new Date().getFullYear()}. All rights reserved by Prospect
        Gaze.
      </Typography>
    </Toolbar>
  )
}

export default BottomBar
