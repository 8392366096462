import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Divider, Hidden, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import MyNavLink from 'utils/MyNavLink'

const FooterItem = ({ title, to }) => (
  <Button
    startIcon={<ChevronRightIcon color="primary" />}
    sx={{
      color: 'text.primary',
      '&:hover': {
        color: 'primary.main',
      },
    }}
  >
    {to === 'contact' ? (
      <>
        <Hidden smDown>
          <MyNavLink to="contact" name="Contact Us" offset={-200} />
        </Hidden>
        <Hidden smUp>
          <MyNavLink to="contact" name="Contact Us" />
        </Hidden>
      </>
    ) : to === 'project' ? (
      <MyNavLink to="project" name="Project Management" />
    ) : (
      <MyNavLink to={to} name={title} />
    )}
  </Button>
)

const Company = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: { xs: 'center', sm: 'flex-start' },
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          mt: 1,
          fontWeight: 800,
          textAlign: { xs: 'center', sm: 'left' },
        }}
        variant="h5"
        gutterBottom
      >
        Company
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',

          flexDirection: 'column',
        }}
      >
        <FooterItem title="About Us" to="about" />
        <FooterItem title="Why Choose Us" to="choose" />
        <FooterItem title="Project Management" to="project" />
        <FooterItem title="Contact Us" to="contact" />
      </Box>
      <Hidden smUp>
        <br />
        <br />
        <Divider
          sx={{
            width: '100%',
            color: 'secondary.main',
          }}
        />
      </Hidden>
    </Box>
  )
}

export default Company
