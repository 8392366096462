import { Button, Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'

const BusinessCard = ({ title, image }) => {
  return (
    <Card
      elevation={0}
      sx={{
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <img src={image} alt="businessCard" width="100%" />
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography align="center" variant="h5" fontWeight="bold" gutterBottom>
          {title}
        </Typography>
        <Typography align="center" variant="caption">
          Mi elit accumsan malesuada suspendisse consectetur maecenas gravida
          conubia et quis aliquet
        </Typography>
        <Button
          variant="outlined"
          sx={{
            mt: 2,
          }}
        >
          Learn more
        </Button>
      </Box>
    </Card>
  )
}

export default BusinessCard
