import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import NewLearnMore from 'utils/NewLearnMore'

const HeroCardTwo = () => {
  return (
    <Card
      sx={{
        p: 4,
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Typography align="left" variant="h5" fontWeight="bold" gutterBottom>
        Financing renewable energy projects
      </Typography>
      <Typography align="justify" variant="subtitle2">
        Prospect Gaze has extensive experiences in appraisal of renewable energy
        projects of complete companies prior to any transactions usually
        requires the execution of due diligence also providing thorough analysis
        of opportunities and risks in changing legal landscapes.
      </Typography>
      <br />
      <NewLearnMore width="20%" />
    </Card>
  )
}

export default HeroCardTwo
