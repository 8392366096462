import {
  Card,
  Chip,
  Container,
  Divider,
  Hidden,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useState } from 'react'
import Animate from 'utils/ScrollAnimation'

const TintCard = () => {
  const [readMore, setReadMore] = useState(false)
  return (
    <Box
      sx={{
        bgcolor: 'var(--secondary)',

        mb: { xs: 15, sm: 8 },
      }}
    >
      <Container maxWidth="lg">
        <Animate anim="fade-down">
          <Hidden smUp>
            <Card
              sx={{
                p: 4,
                bgcolor: '#d5f3e6',
                transform: 'translateY(-60px)',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <Divider>
                <Chip
                  label="O&M supervision"
                  sx={{
                    py: 2.5,
                    typography: {
                      xs: 'h6',
                      sm: 'h5',
                    },
                    fontWeight: 'bold !important',
                  }}
                />
              </Divider>

              <br />
              <Typography
                sx={{
                  transition: 'all 0.3s ease-in-out',
                  textAlign: 'justify',
                  fontWeight: 400,
                }}
                variant="subtitle1"
              >
                Prospect Gaze take care of monitoring the performance of the
                asset during operation and maintenance. To this end, we maintain
                direct and ongoing communication with the team of professionals
                working on the project.{' '}
                {!readMore && (
                  <Box
                    onClick={() => setReadMore((prev) => !prev)}
                    sx={{
                      display: 'inline',
                      cursor: 'pointer',
                      color: 'primary.main',
                    }}
                  >
                    <span>read more</span>
                  </Box>
                )}
                {readMore &&
                  'Here we can work holistically or on specific tasks – asset and evacuation infrastructure, project failures, defects and breakdowns, energy harvesting systems, trip reports, electrical system failures and defects, site maintenance and project performance.Prospect Gaze also provide support in the annual validation of the preventive O&M plan together with the fulfillment of the obligations contained in the contract and the handling of any claims. We also coordinate the required corrective maintenance repairs at the plant with the O&M company.'}
              </Typography>
            </Card>
          </Hidden>
          <Hidden smDown>
            <Card
              sx={{
                p: 4,
                bgcolor: '#d5f3e6',
                transform: 'translateY(-60px)',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <Divider>
                <Chip
                  label="O&M supervision"
                  sx={{
                    py: 2.5,
                    typography: {
                      xs: 'h6',
                      sm: 'h5',
                    },
                    fontWeight: 'bold !important',
                  }}
                />
              </Divider>

              <br />
              <Typography
                sx={{
                  transition: 'all 0.3s ease-in-out',
                  textAlign: 'justify',
                  fontWeight: 400,
                }}
                variant="subtitle1"
              >
                Prospect Gaze take care of monitoring the performance of the
                asset during operation and maintenance. To this end, we maintain
                direct and ongoing communication with the team of professionals
                working on the project. Here we can work holistically or on
                specific tasks – asset and evacuation infrastructure, project
                failures, defects and breakdowns, energy harvesting systems,
                trip reports, electrical system failures and defects, site
                maintenance and project performance.{' '}
                {!readMore && (
                  <Box
                    onClick={() => setReadMore((prev) => !prev)}
                    sx={{
                      display: 'inline',
                      cursor: 'pointer',
                      color: 'primary.main',
                    }}
                  >
                    <span>read more</span>
                  </Box>
                )}
                {readMore &&
                  'Prospect Gaze also provide support in the annual validation of the preventive O&M plan together with the fulfillment of the obligations contained in the contract and the handling of any claims. We also coordinate the required corrective maintenance repairs at the plant with the O&M company.'}
              </Typography>
            </Card>
          </Hidden>
        </Animate>
      </Container>
    </Box>
  )
}

export default TintCard
