// import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Hidden,
  TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'

const ContactFormGrid = () => {
  return (
    <Container
      sx={{
        my: 10,
      }}
    >
      <Grid container spacing={7}>
        <Grid item xs={12} sm={5}>
          <Box
            sx={{
              mt: { xs: 0, sm: 5 },
            }}
          >
            <Typography
              align="left"
              fontWeight="bold"
              variant="subtitle2"
              color="primary.main"
              gutterBottom
            >
              GET IN TOUCH
            </Typography>

            <Typography
              sx={{
                typography: {
                  xs: 'h4',
                  sm: 'h3',
                },
                fontWeight: 'bold !important',
              }}
              gutterBottom
            >
              Have a question or feedback?
            </Typography>
            <Hidden smDown></Hidden>
            <Typography align="left" variant="caption" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </Typography>
            <br />
            <Hidden smDown>
              <br />
            </Hidden>
            <Box
              sx={{
                mt: 4,
                height: 2,
                width: 100,
                bgcolor: 'primary.main',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Card
            sx={{
              p: 5,
            }}
          >
            <Grid container spacing={3} alignItems="stretch">
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="filled"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'var(--tertiary)',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company"
                  variant="filled"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'var(--tertiary)',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="filled"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'var(--tertiary)',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  variant="filled"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'var(--tertiary)',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Subject"
                  variant="filled"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'var(--tertiary)',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label="Message"
                  variant="filled"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'var(--tertiary)',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{
                    color: 'white',
                  }}
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContactFormGrid
