import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import about_bg from 'assets/About/about_bg.jpg'
import BottomNav from 'components/Navbar/BottomNav'

const AboutHeader = () => {
  return (
    <Box
      sx={{
        py: { xs: 12, sm: 18 },
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), 
         url(${about_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: {
          xs: 'scroll',
          sm: 'fixed',
        },
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <BottomNav />
      </Box>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          align="center"
          color="white"
          sx={{
            typography: {
              xs: 'h3',
              sm: 'h2',
            },
            fontWeight: 'bold !important',
            '& span': {
              color: 'var(--primary)',
            },
          }}
          gutterBottom
        >
          About Us
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          color="white"
          gutterBottom
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </Typography>
      </Container>
    </Box>
  )
}

export default AboutHeader
