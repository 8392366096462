import { Button, Card, Grid, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'

const ContactCard = () => {
  return (
    <Card
      sx={{
        p: { xs: 3, sm: 5 },
        transform: { xs: 'translateY(0)', sm: 'translateY(-100px)' },
      }}
    >
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: '0 !important',
          },
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            align="left"
            variant="subtitle1"
            fontWeight="bold"
            component="label"
            htmlFor="name"
            gutterBottom
            sx={{
              cursor: 'pointer',
            }}
          >
            Name
          </Typography>
          <TextField
            id="name"
            fullWidth
            placeholder="Name"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                border: 0,
                borderRadius: 4,
                backgroundColor: 'var(--tertiary)',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            align="left"
            variant="subtitle1"
            fontWeight="bold"
            component="label"
            htmlFor="company"
            gutterBottom
            sx={{
              cursor: 'pointer',
            }}
          >
            Company
          </Typography>
          <TextField
            id="company"
            fullWidth
            placeholder="Company"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 4,
                backgroundColor: 'var(--tertiary)',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            align="left"
            variant="subtitle1"
            fontWeight="bold"
            component="label"
            htmlFor="email"
            gutterBottom
            sx={{
              cursor: 'pointer',
            }}
          >
            Email
          </Typography>
          <TextField
            id="email"
            fullWidth
            placeholder="Email"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 4,
                backgroundColor: 'var(--tertiary)',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            align="left"
            variant="subtitle1"
            fontWeight="bold"
            component="label"
            htmlFor="name"
            gutterBottom
            sx={{
              cursor: 'pointer',
            }}
          >
            Phone
          </Typography>
          <TextField
            id="phone"
            fullWidth
            placeholder="Phone"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 4,
                backgroundColor: 'var(--tertiary)',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="left"
            variant="subtitle1"
            fontWeight="bold"
            component="label"
            htmlFor="message"
            gutterBottom
            sx={{
              cursor: 'pointer',
            }}
          >
            Message
          </Typography>
          <TextField
            id="message"
            multiline
            rows={4}
            fullWidth
            placeholder="Message"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 4,
                backgroundColor: 'var(--tertiary)',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{
              color: 'white',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: 'var(--primary)',
              },
            }}
            fullWidth
            size="large"
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ContactCard
