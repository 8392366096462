import { Box } from '@mui/material'
import AboutCompany from 'components/AboutUs/AboutCompany'
import AboutHeader from 'components/AboutUs/AboutHeader'
import OurBusiness from 'components/AboutUs/OurBusiness'
import Partners from 'components/AboutUs/Partners'
import TintCard from 'components/AboutUs/TintCard'

const About = () => {
  return (
    <Box>
      <AboutHeader />
      <AboutCompany />
      <OurBusiness />
      <TintCard />
      <Partners />
    </Box>
  )
}

export default About
